import { Pipe, PipeTransform } from "@angular/core";
import { Path } from "shared/common";

@Pipe({ name: "image" })
export class ImagePipe implements PipeTransform {
	// TODO: set default format to "0,0" once it's supported
	transform(value: string, size?: string): string {
		return ImagePipe.transform(value, size);
	}

	static transform(value: string, size?: string) {
		const dashSize = size ? `-${size}` : "";
		const path = new Path(value);
		const ext = path
			.extension()
			.map((ext) => `.${ext}`)
			.unwrapOr("");
		const fileStem = path.fileStem().unwrapOr(null);
		if (fileStem) {
			return `https://dfm-cdn.com/${path.parent().unwrap()}/${fileStem}${dashSize}${ext}`;
		} else {
			return "";
		}
	}
}
