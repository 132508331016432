import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { iterObj } from "@common/iter";
import { BehaviorSubject, of, Subject } from "rxjs";
import { delay, first, map, shareReplay, startWith, switchMap, take } from "rxjs/operators";
import { iter, tuple } from "shared/common";
import { RestService } from "./rest.service";
import { UserService } from "./user.service";

@Injectable({ providedIn: "root" })
export class OffersService {
	private statement = this.rest.init("statement");
	private refreshWishlistS = new Subject<void>();

	cartBS = new BehaviorSubject(loadCart());

	cart$ = this.cartBS.asObservable().pipe(delay(1000), shareReplay(1));

	totalItems$ = this.cart$.pipe(
		map((cart) =>
			iterObj(cart)
				.map(([offer_variationid, value]: any) => value.qty)
				.sum(),
		),
	);

	wishlist$ = this.refreshWishlistS.pipe(
		startWith(null),
		switchMap(() => this.user.loggedIn$),
		switchMap((loggedIn) =>
			loggedIn ? this.http.post("/api/statement/GetWishlist", {}).pipe(map((res: any) => res.results)) : of([]),
		),
		map((res) => new Map<number, any>(res.map((row: any) => tuple(row.offerid, row)))),
		shareReplay(1),
	);

	offers$ = this.statement.post$("OffersPerBrand", {}).pipe(
		map((res: any) =>
			iter(res.results)
				.map((row: any) => tuple(row.brandid, row))
				.toGroupMap()
				.toMap(),
		),
		shareReplay(1),
	);

	fileOffers$ = this.statement.post$("GetBrandOffersWithFiles", {}, "GetBrandOffersWithFiles").pipe(
		map((res: any) =>
			iter(res.results)
				.map((row: any) => tuple(row.brandid, row))
				.toGroupMap()
				.toMap(),
		),
		shareReplay(1),
	);

	brands$ = this.offers$.pipe(
		switchMap((offers: any) => {
			const vars = { brandids: iter(offers.keys()).toArray() };
			return this.statement.post$("GetBrandsIn", { vars });
		}),
		map((res: any) =>
			iter(res.results)
				.map((row: any) => tuple(row.brandid, row))
				.toMap(),
		),
		shareReplay(1),
	);

	fileBrands$ = this.fileOffers$.pipe(
		switchMap((offers: any) => {
			const vars = { brandids: iter(offers.keys()).toArray() };
			return this.statement.post$("GetBrandsIn", { vars });
		}),
		map((res: any) =>
			iter(res.results)
				.map((row: any) => tuple(row.brandid, row))
				.toMap(),
		),
		shareReplay(1),
	);

	constructor(private http: HttpClient, private rest: RestService, private user: UserService) {}

	addToCart(offer_variationid: number, qty: number, cardMessage: string) {
		this.refreshCart();
		const id = offer_variationid.toString();
		const tempQty = (this.cartBS.value[id] || 0) + qty;
		this.cartBS.value[id] = { qty: tempQty, cardMessage: cardMessage };
		localStorage.setItem("cart", JSON.stringify(this.cartBS.value));
		this.cartBS.next(this.cartBS.value);
		return this.cartBS.asObservable().pipe(delay(1000), shareReplay(1));
	}

	removeFromCart(idx: number) {
		this.refreshCart();
		delete this.cartBS.value[idx.toString()];
		localStorage.setItem("cart", JSON.stringify(this.cartBS.value));
		this.cartBS.next(this.cartBS.value);
		return this.cartBS.asObservable().pipe(delay(1000), shareReplay(1));
	}

	delCart() {
		localStorage.removeItem("cart");
		this.cartBS.next({});
		return this.cartBS.asObservable().pipe(delay(1000), shareReplay(1));
	}

	saveCart() {
		for (const [_, item] of iterObj(this.cartBS.value)) {
			const itemAny = item as any;
			if (itemAny.qty <= 0) {
				itemAny.qty = 1;
			}
		}
		localStorage.setItem("cart", JSON.stringify(this.cartBS.value));
		this.cartBS.next(this.cartBS.value);
	}

	toggleWishlistItem(offerid: number) {
		if (!this.user.loggedIn()) {
			throw new Error("user must be logged in to add to wishlist");
		}

		const vars = { vars: { offerid } };
		return this.wishlist$.pipe(
			first(),
			switchMap((wishlist) =>
				wishlist.has(offerid)
					? this.http.post("/api/statement/RemoveFromWishlist", vars)
					: this.http.post("/api/statement/AddToWishlist", vars),
			),
			switchMap(() => this.refreshWishlist()),
		);
	}

	private refreshWishlist() {
		const ret = this.wishlist$.pipe(take(1));
		this.refreshWishlistS.next();
		return ret;
	}

	private refreshCart() {
		this.cartBS.next(JSON.parse(localStorage.getItem("cart") || "{}"));
	}
}

function loadCart() {
	let cartAny = JSON.parse((typeof localStorage !== "undefined" && localStorage.getItem("cart")) || "{}");
	if (Array.isArray(cartAny)) {
		const cart = cartAny as any[];
		cartAny = iter(cartAny).fold({} as any, (acc, item) => {
			const id = item.offer_variationid.toString();
			const tmpQty = (acc[id] || 0) + item.qty;
			acc[id] = { qty: tmpQty, cardMessage: item.cardMessage };
			return acc;
		});
		localStorage.setItem("cart", JSON.stringify(cartAny));
	}
	return cartAny as any;
}
