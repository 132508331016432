import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { iter, tuple } from "@common/iter";
import { TransferRxService } from "@common/services/transfer-rx.service";
import { EMPTY, Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";

@Injectable()
export class RouteResolver implements Resolve<Observable<any>> {
	constructor(private http: HttpClient, private transfer: TransferRxService, private router: Router) {}

	resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<any> {
		const url = "/" + route.url.map((x) => x.path).join("/");

		const matches = url.match(new RegExp(route.data.regex))!;

		const rewriteToSplit = iter((route.data.rewriteTo as string).split("&")).map(
			(x) => x.split("=") as [string, string],
		);

		const rewriteMap = new Map(rewriteToSplit.map(([name, num]) => tuple(parseInt(num), name)));

		const vars = iter(matches)
			.enumerate()
			.skip(1)
			.map(([i, match]) => tuple(rewriteMap.get(i)!, match))
			.toObject();
		Object.assign(vars, rewriteToSplit.filter(([_, val]) => isNaN(Number(val))).toObject());
		return this.transfer
			.transfer$("routeStmt:" + JSON.stringify({ url, vars }), () =>
				this.http.post("/api/page/info", { url, vars }),
			)
			.pipe(switchMap((response: any) => (response.success ? this.redirectOrPass(response, vars) : EMPTY)));
	}

	redirectOrPass(response: any, vars: any) {
		if (response.metaInfo.redirect) {
			this.router.navigateByUrl(response.metaInfo.redirect);
			return EMPTY;
		} else {
			return of({ stmt: { success: true, results: [response.metaInfo] }, params: vars });
		}
	}
}
