import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ImagePipe } from "@common/pipes/image";

@NgModule({
	declarations: [ImagePipe],
	imports: [CommonModule],
	exports: [ImagePipe],
})
export class ImageModule {}
