import { ChangeDetectionStrategy, Component } from "@angular/core";
import { faShoppingCart } from "@fortawesome/pro-solid-svg-icons";
import { filter, map } from "rxjs/operators";
import { AppService } from "../app.service";
import { OffersService } from "../offers.service";
import { UserService } from "../user.service";

// TODO: generate this component at build time to get rid of the ngSwitch, so the unused templates can be treeshaken

@Component({
	selector: "cm-header",
	template: `
		<header id="nav-header" class="header shadow bg-white rounded">
			<div class="container-fluid">
				<div class="row align-items-stretch" itemscope itemtype="http://schema.org/WPHeader">
					<div class="col-6 col-lg-2 d-flex flex-column flex-center py-2">
						<a routerLink="/">
							<img
								[src]="app.logo$ | async | image : 'm'"
								alt="{{ app.dealer$ | async }}"
								class="logo img-fluid"
							/>
						</a>
					</div>
					<div class="col-6 col-lg-8 d-flex flex-column justify-content-between">
						<div class="row d-none d-lg-block">
							<div
								class="col-12 d-flex py-2 bg-primary address-bar rounded-bottom align-items-center justify-content-center"
							>
								<ng-container *ngFor="let location of app.locationAddresses$ | async; first as first">
									<img
										src="https://dfm-cdn.com/static/52/icon-pin-white.png"
										alt="pin"
										class="img-fluid mx-1 address"
										style="width: 25px;"
									/>
									<a [href]="location.link" class="address text-white mx-1">
										{{ location.cityState }}
									</a>
									<a
										href="tel:{{ location.phoneNumber }}"
										class="text-white mx-1 {{ !first ? 'address' : '' }}"
										>{{ location.phoneNumber }}</a
									>
								</ng-container>
							</div>
						</div>
						<div class="row">
							<div
								class="col-12 d-flex justify-content-end justify-content-lg-center pl-0 pr-1 menu-holder"
							>
								<cm-menu2 [appMenuid]="1" rootStyle="inline" subStyle="unstyled"></cm-menu2>
							</div>
						</div>
					</div>
					<div class="d-none d-lg-flex flex-column justify-content-center col-lg-2">
						<div class="row py-1 align-items-center">
							<div class="col-12 text-center">
								<ng-container *ngIf="user.loggedIn$ | async; else login">
									<img
										src="https://dfm-cdn.com/static/52/icon-acct-black.png"
										alt="account"
										class="img-fluid address"
										style="width: 25px;"
									/>
									<span class="mx-1 font-weight-bold make-d-block">{{ friendlyName$ | async }}</span>
									<a
										routerLink="/my-account"
										class="text-uppercase font-weight-bold mx-1 text-black make-d-block"
										>Account</a
									>
									<a
										href="javascript:void(0)"
										role="button"
										class="text-uppercase font-weight-bold mx-1 text-black make-d-block"
										(click)="user.logOut()"
										>Sign Out</a
									>
								</ng-container>
								<ng-template #login>
									<a
										href="javascript:void(0)"
										role="button"
										class="text-uppercase font-weight-bold mx-2"
										(click)="showLogin = true"
										>Log In</a
									>
								</ng-template>
							</div>
						</div>
						<div class="row" *ngIf="user.loggedIn$ | async">
							<div class="col-12">
								<a routerLink="/my-order" class="btn btn-success btn-sm rounded w-100 text-white my-1">
									<fa-icon [icon]="faShoppingCart"></fa-icon>
									ORDER
									<span class="address" *ngIf="offers.totalItems$ | async as totalItems"
										>| {{ totalItems }} Items</span
									>
								</a>
							</div>
						</div>
						<div class="row" *ngIf="!(user.loggedIn$ | async)">
							<div class="col-12">
								<a
									href="javascript:void(0)"
									role="button"
									class="btn btn-success btn-sm rounded w-100 text-white my-1"
									(click)="showLogin = true"
								>
									<fa-icon [icon]="faShoppingCart"></fa-icon>
									ORDER
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
		<cm-login-modal [(show)]="showLogin" (loggedIn)="showLogin = false"></cm-login-modal>
	`,
	styleUrls: ["./header.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
	showLogin = false;
	friendlyName$ = this.user.user$.pipe(
		filter((user) => user),
		map((user) => user!.first_name || user!.user_login),
	);
	faShoppingCart = faShoppingCart;

	constructor(public app: AppService, public user: UserService, public offers: OffersService) {}
}
