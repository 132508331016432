declare const __DEFINE_SITE_CONFIGID: number; // provided by DefinePlugin

import { IEnvironment } from "./environment-types";
import { environment as environment38 } from "./environment.38";
import { environmentDefault } from "./environment.default";

export { IEnvironment } from "./environment-types";

export const environment: IEnvironment = {
	...environmentDefault,
	...environment38,
	production: true,
};
