import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { HoursComponent } from "./hours.component";

@NgModule({
	declarations: [HoursComponent],
	imports: [CommonModule],
	exports: [HoursComponent],
})
export class HoursModule {}
