import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { isPrerendering } from "shared";

if (environment.production) {
	enableProdMode();
}

document.addEventListener("DOMContentLoaded", () => {
	platformBrowserDynamic()
		.bootstrapModule(AppModule)
		.then(() => {
			if ("serviceWorker" in navigator && environment.production) {
				window.addEventListener("load", () => navigator.serviceWorker.register("ngsw-worker.js"));
			}
		})
		.catch((err) => console.log(err));
});

if (isPrerendering()) {
	document.body.style.display = "none";
}
