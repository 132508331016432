import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes, UrlMatcher, UrlMatchResult, UrlSegment } from "@angular/router";
import { ServiceWorkerModule } from "@angular/service-worker";
import { RootComponent } from "@core/app/pages/root/root.component";
import { RouteResolver } from "@core/app/route-resolver.service";
import { environment } from "@core/environments/environment";
import { PrerenderModule } from "shared";
import { ToastNoAnimationModule } from "ngx-toastr";
import { ModalModule } from "shared";
import { CoreModule } from "./core.module";
import { PAGE_DATA } from "./generated/page-data";

export const routes: Routes = [
	{
		matcher: routeMatcher0 as UrlMatcher,
		loadChildren: () => import("./pages/page-admin/page-admin.module").then((m) => m.PageAdminModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Admin",
			pageTemplateId: 88,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/new-admin).*$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher1 as UrlMatcher,
		loadChildren: () => import("./pages/page-account/page-account.module").then((m) => m.PageAccountModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Account",
			pageTemplateId: 60,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/my-account)(?:\\?.*)?$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher2 as UrlMatcher,
		loadChildren: () => import("./pages/page-home/page-home.module").then((m) => m.PageHomeModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Home",
			pageTemplateId: 39,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/)(\\?.*)?$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher4 as UrlMatcher,
		loadChildren: () => import("./pages/page-order/page-order.module").then((m) => m.PageOrderModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Order",
			pageTemplateId: 343,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/my-order)$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher5 as UrlMatcher,
		loadChildren: () => import("./pages/page-contact/page-contact.module").then((m) => m.PageContactModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Contact",
			pageTemplateId: 138,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/contact)$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher6 as UrlMatcher,
		loadChildren: () => import("./pages/page-equipment/page-equipment.module").then((m) => m.PageEquipmentModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Equipment",
			pageTemplateId: 342,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/equipment)$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher7 as UrlMatcher,
		loadChildren: () => import("./pages/page-products/page-products.module").then((m) => m.PageProductsModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Products",
			pageTemplateId: 257,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/products)(?:\\/([0-9]+))?(?:\\?.*)?$",
			rewriteTo: "content_page_url=1&page=2",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher8 as UrlMatcher,
		loadChildren: () => import("./pages/page-product/page-product.module").then((m) => m.PageProductModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Product",
			pageTemplateId: 255,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/product\\/(?:[^\\/]+))(?:\\?.*)?$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher9 as UrlMatcher,
		loadChildren: () => import("./pages/page-resources/page-resources.module").then((m) => m.PageResourcesModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Resources",
			pageTemplateId: 338,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/resources).*$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher11 as UrlMatcher,
		loadChildren: () =>
			import("./pages/page-prefilled-socks/page-prefilled-socks.module").then((m) => m.PagePrefilledSocksModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "PrefilledSocks",
			pageTemplateId: 366,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/prefilled-socks).*$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher10 as UrlMatcher,
		loadChildren: () => import("./pages/page-crm/page-crm.module").then((m) => m.PageCrmModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "CRM",
			pageTemplateId: 228,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/lead-management).*$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher12 as UrlMatcher,
		loadChildren: () =>
			import("./pages/page-new-customer/page-new-customer.module").then((m) => m.PageNewCustomerModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "NewCustomer",
			pageTemplateId: 384,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/new-customer).*$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher3 as UrlMatcher,
		loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Page",
			pageTemplateId: 13,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/.+?)(?:(\\/[0-9]+))?(?:\\?.*)?$",
			rewriteTo: "content_page_url=1&page=2",
			stmtid: 85,
		},
	},
];

export function routeMatcher0(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/new-admin).*$/, segments, true);
}
export function routeMatcher1(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/my-account)(?:\?.*)?$/, segments, false);
}
export function routeMatcher2(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/)(\?.*)?$/, segments, false);
}
export function routeMatcher3(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/.+?)(?:(\/[0-9]+))?(?:\?.*)?$/, segments, false);
}
export function routeMatcher4(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/my-order)$/, segments, false);
}
export function routeMatcher5(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/contact)$/, segments, false);
}
export function routeMatcher6(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/equipment)$/, segments, false);
}
export function routeMatcher7(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/products)(?:\/([0-9]+))?(?:\?.*)?$/, segments, false);
}
export function routeMatcher8(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/product\/(?:[^\/]+))(?:\?.*)?$/, segments, false);
}
export function routeMatcher9(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/resources).*$/, segments, false);
}
export function routeMatcher10(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/lead-management).*$/, segments, false);
}
export function routeMatcher11(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/prefilled-socks).*$/, segments, false);
}
export function routeMatcher12(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/new-customer).*$/, segments, false);
}

export function routeMatcher(regex: RegExp, segments: UrlSegment[], hasChildRouter: boolean): UrlMatchResult | null {
	if (regex.test("/" + segments.map((x) => x.path).join("/"))) {
		if (hasChildRouter) {
			return { consumed: [segments[0]] };
		} else {
			return { consumed: segments };
		}
	} else {
		return null;
	}
}

@NgModule({
	declarations: [RootComponent],
	imports: [
		CommonModule,
		CoreModule.forRoot(),
		ToastNoAnimationModule.forRoot({ positionClass: "toast-top-center" }),
		PrerenderModule,
		RouterModule.forRoot(routes, {
			initialNavigation: "enabledBlocking",
			scrollPositionRestoration: "enabled",
			anchorScrolling: "enabled",
			onSameUrlNavigation: "reload",
		}),
		ServiceWorkerModule.register("./ngsw-worker.js", { enabled: environment.production }),
		ModalModule,
	],
	providers: [{ provide: "PAGE_DATA", useValue: PAGE_DATA }],
	entryComponents: [RootComponent],
	bootstrap: [RootComponent],
})
export class AppModule {}
