import { HttpClientModule } from "@angular/common/http";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { config } from "@fortawesome/fontawesome-svg-core";
import { AccountModule } from "./account/account.module";
import { EditWidgetComponent } from "./edit-widget/edit-widget.component";
import { FooterComponent } from "./footer/components/footer.component";
import { HeaderComponent } from "./header/header.component";
import { HoursModule } from "./hours/hours.module";
import { ImageModule } from "./image/image.module";
import { LazyModule } from "./lazy/lazy.module";
import { Menu2Component } from "./menu2/menu2.component";
import { RouteResolver } from "./route-resolver.service";
import { UtilModule } from "./util/util.module";

@NgModule({
	declarations: [HeaderComponent, FooterComponent, EditWidgetComponent, Menu2Component],
	imports: [
		BrowserModule,
		HttpClientModule,
		RouterModule.forChild([]),
		UtilModule,
		ImageModule,
		AccountModule,
		FontAwesomeModule,
		LazyModule,
		HoursModule,
	],
	exports: [UtilModule, HeaderComponent, FooterComponent, EditWidgetComponent],
})
export class CoreModule {
	static forRoot(): ModuleWithProviders<CoreModule> {
		return { ngModule: CoreModule, providers: [RouteResolver] };
	}
	constructor() {
		config.autoAddCss = false;
	}
}
