import { Directive, Input, ViewContainerRef, TemplateRef } from "@angular/core";

@Directive({ selector: "[cmVar]" })
export class VarDirective {
	@Input()
	set cmVar(context: any) {
		this.context.$implicit = this.context.cmVar = context;
		this.updateView();
	}

	context: any = {};

	constructor(private vcRef: ViewContainerRef, private templateRef: TemplateRef<any>) {}

	updateView() {
		this.vcRef.clear();
		this.vcRef.createEmbeddedView(this.templateRef, this.context);
	}
}
